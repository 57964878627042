import { ResultOf } from '@graphql-typed-document-node/core';
import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../config';
import { GameMode } from '../consts';
import { AnticipationInfo, ISettledBet, MessageWinBannerProps, ReelSet, ReelStopSoundType } from '../global.d';
import {
  setAnticipationSymbolType,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setDefaultReelSetId,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAllowForceReelStop,
  setIsBuyFeaturePurchased,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeRoundBonus,
  setIsFreeSpinsWin,
  setIsRespinRevivalWaiting,
  setIsRespinRevivePattern,
  setIsRevokeThrowingError,
  setIsSpinShortCut,
  setIsStopOnFeatureWin,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelSet,
  setPrevReelsPosition,
  setReelSetId,
  setRespinCnt,
  setRespinSymbolType,
  setResumeRespinResult,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { userBonusFragment } from '../gql/fragment';
import { isStoppedGql } from '../gql/query';
import {
  findBuyFeatureTypeByBonusId,
  getBonusFromRewards,
  getBonusKind,
  getNonNullableValue,
  getRespinGuaranteedNum,
  getRespinLimitBonusAmount,
  getRespinMultipleCount,
  getRespinResumeCount,
  getRespinSymbolType,
  getSpinResult3x5,
  isBaseGameMode,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinsMode,
  isRespinLimitBonus,
  isRespinRevivePattern,
  isRespinReviveSpinResult,
  isRespinSlotId,
  isSuccessRespin,
  normalizeCoins,
  showCurrency,
  updateCoinValueAfterBonuses,
} from '../utils';

import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import { AnnounceContainer } from './announce/announceContainer';
import { AnnounceConvTbl, AnnounceDataTbl, AnnounceType, RespinActionInfo } from './announce/config';
import { getRandomFromUUID, getResultFromTbl } from './announce/utils';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { BuyFeaturePopup } from './buyFeature/buyFeaturePopup';
import { BuyFeaturePopupConfirm } from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_LONG_START_REELID,
  ANTICIPATION_SHORT_START_REELID,
  BIG_WIN_END_DURATION,
  EventTypes,
  FREE_SPINS_TIME_OUT_BANNER,
  JINGLE_TO_WIN_DURATION,
  REELS_AMOUNT,
  RESPIN_ENDING_DELAY_DURATION,
  RESPIN_REEL_INDEX,
  REVIVAL_ANIMATION_DURATION,
  REVIVAL_REEL_STOP_DURATION,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
  WinStages,
  eventManager,
} from './config';
import { BonusKind } from './config/bonusInfo';
import { createUIButtonContainer } from './controlButtons';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import { getUserActiveBonuses } from './freeRoundBonus/helper';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import OpeningScreen from './openingScreen/openingScreen';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsAnimationContainer } from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: RecursiveNonNullable<ISlotConfig>;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public spinResult: Icon[];

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotConfig as RecursiveNonNullable<ISlotConfig>,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotAnimationContainer: SlotsAnimationContainer;

  public announceContainer: AnnounceContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public buyFeatureBtn?: BuyFeatureBtn;

  public buyFeaturePopup?: BuyFeaturePopup;

  public buyFeaturePopupConfirm?: BuyFeaturePopupConfirm;

  private opening: OpeningScreen;

  private phoenix: Phoenix;

  private announceType: AnnounceType = 'None';

  private anticipationInfos: AnticipationInfo[] = [];

  private stopSymbolSounds: ReelStopSoundType[] = [];

  private constructor(
    application: PIXI.Application,
    slotConfig: RecursiveNonNullable<ISlotConfig>,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const lastResult = setUserLastBetResult();
    const isLastBuyFeatureMystery = lastResult?.userBonus?.bonusId
      ? findBuyFeatureTypeByBonusId(lastResult.userBonus.bonusId) === 'M'
      : false;

    const startPosition = isLastBuyFeatureMystery
      ? lastResult!.data.features.gameRoundStore.baseReelPositions
      : lastResult
      ? getNonNullableValue(lastResult.result.reelPositions)
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    const baseReelId = lastResult?.data.features.gameRoundStore.baseReelId! ?? setDefaultReelSetId();

    const reelSet = this.makeRespinReelSet(baseReelId, lastResult ? lastResult.reelSetId : setDefaultReelSetId());
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult3x5({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    setPrevReelSet(reelSet);
    this.spinResult = spinResult;
    this.slotAnimationContainer = new SlotsAnimationContainer();
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.announceContainer = new AnnounceContainer(spinResult);

    this.background = new Background();

    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.gameView = new GameView({
      announceContainer: this.announceContainer,
      slotStopDisplayContainer: this.slotAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      miniPayTableContainer: this.miniPayTableContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipWinAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipWinAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.buyFeatureBtn = new BuyFeatureBtn();
      this.buyFeaturePopup = new BuyFeaturePopup();
      this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
      this.gameView.addChild(
        this.buyFeatureBtn,
        new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG),
        this.buyFeaturePopup,
        this.buyFeaturePopupConfirm,
      );
      this.buyFeatureBtn.zIndex = 20;
      this.buyFeaturePopup.zIndex = 21;
      this.buyFeaturePopupConfirm.zIndex = 22;
    }
    const uiButtonContainer = createUIButtonContainer();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.safeArea.addChild(this.gameView);

    this.opening = new OpeningScreen();
    this.phoenix = new Phoenix();

    this.application.stage.addChild(this.background);
    this.application.stage.addChild(this.safeArea);
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(uiButtonContainer);
    this.application.stage.addChild(this.opening);
    this.application.stage.addChild(this.phoenix);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);

    if (setBrokenBuyFeatureGame() !== '') {
      const initWait = Tween.createDelayAnimation(500);
      initWait.addOnComplete(() => {
        eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, setBrokenBuyFeatureGame());
      });
      initWait.start();
      this.opening.BrokenGameStart();
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    } else if (setBrokenGame()) {
      this.state = SlotMachineState.WINNING;
      this.onBrokenGame();
      setResumeRespinResult(true);

      this.opening.BrokenGameStart();
    } else {
      this.opening.startAnimation();
    }
  }

  private onBrokenGame(): void {
    const lastResult = setUserLastBetResult()!;

    setIsFreeSpinsWin(true);
    setRespinSymbolType(getRespinSymbolType(setCurrentBonus().bonusId)!);

    const resumeRespinCount = getRespinResumeCount();
    const bgType = RespinActionInfo['respinBgType'][resumeRespinCount];

    const settings = {
      mode: GameMode.FREE_SPINS,
      reelPositions: lastResult.result.reelPositions,
      reelSetId: setDefaultReelSetId(),
      bgType: bgType,
    };

    eventManager.emit(EventTypes.CHANGE_MODE, getNonNullableValue(settings));
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setCurrentFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    }
    eventManager.emit(EventTypes.SET_RESPIN_VIEW);

    if (isRespinReviveSpinResult(this.spinResult)) {
      eventManager.emit(EventTypes.REPLACE_SLOT_SYMBOL, 2, 1, setRespinSymbolType());
    }
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.END_FREESPINS, this.endFreeSpins.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    AudioApi.stop({ type: ISongs.XT003S_spin_loop });
    this.setState(SlotMachineState.IDLE);
    setIsSpinShortCut(false);
    this.isSpinInProgressCallback();

    const spinResult = getSpinResult3x5({
      reelPositions: setPrevReelsPosition(),
      reelSet: setPrevReelSet(),
      icons: this.slotConfig.icons,
    });
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION, settings.mode === GameMode.REGULAR);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, setNextResult()!.balance.settled);
      if (previousGameMode === GameMode.FREE_SPINS) {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setCurrentFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );

        updateCoinValueAfterBonuses();
      }
      this.setState(SlotMachineState.IDLE);
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = setCurrentBonus();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      const callback = () => {
        setCurrentBonus({ ...bonus, isActive: true });
        this.setState(SlotMachineState.IDLE);
      };
      if (!setBrokenGame()) {
        setCurrentBonus({
          ...bonus,
        });
      }
      //eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

      const isAutoSkipTitle = setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin() ? true : false;

      if (setBrokenGame() && setRespinCnt() > 0) {
        const brokenGameDelay = Tween.createDelayAnimation(2000);
        brokenGameDelay.addOnComplete(() => {
          this.setState(SlotMachineState.IDLE);
        });
        brokenGameDelay.start();
      } else if (isAutoSkipTitle) {
        AudioApi.play({ type: ISongs.XT003S_feature_trigger, stopPrev: true });
        // auto continue
        const titleDispWait = Tween.createDelayAnimation(setBrokenGame() ? 2000 : 2000);
        titleDispWait.addOnComplete(() => {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          //this.setState(SlotMachineState.IDLE);
        });
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          callback: callback,
          preventDefaultDestroy: false,
          onInitCallback: () => {
            titleDispWait.start();
          },
        });
      } else {
        this.isSlotBusyCallback();
        //title wait
        AudioApi.play({ type: ISongs.XT003S_feature_trigger, stopPrev: true });
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          callback: callback,
          preventDefaultDestroy: false,
        });
      }
    }
  }

  private startFreeSpins(): void {
    setRespinCnt(0);
    setIsFreeSpinsWin(true);

    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: getNonNullableValue(this.nextResult?.bet.result.reelPositions),
      reelSetId: setDefaultReelSetId(),
      bgType: 'respin01',
    });
    eventManager.emit(EventTypes.START_FREESPINS);
  }

  private hideWinCount() {
    this.skipWinAnimations();

    eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private async endFreeSpins(): Promise<void> {
    setIsRespinRevivalWaiting(false);
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);

      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.REGULAR,
        reelSetId: setDefaultReelSetId(),
        reelPositions: getNonNullableValue(this.nextResult?.bet.result.reelPositions),
        bgType: 'default',
      });
    };
    this.hideWinCount();

    if (setIsFreeRoundBonus()) {
      const bonus = await getUserActiveBonuses();
      eventManager.emit(EventTypes.GET_FREE_ROUND_BONUS, bonus.data.userBonuses);
    }

    const winMessageBannerOptions: MessageWinBannerProps = {
      totalWin: `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
      totalWinAmount: setFreeSpinsTotalWin(),
      preventDefaultDestroy: true,
    };

    if (!setIsContinueAutoSpinsAfterFeature()) {
      winMessageBannerOptions.callback = callback;
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      winMessageBannerOptions.onInitCallback = () => delay.start();
    }
    eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, winMessageBannerOptions);

    setBrokenGame(false);
    this.isSlotBusyCallback();
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(reelIdx = REELS_AMOUNT - 1): void {
    this.reelsContainer.reels[reelIdx]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      setIsSpinShortCut(true);
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          const removeOnCompleteListenerReelIdx = isFreeSpinsMode(setGameMode()) ? RESPIN_REEL_INDEX : REELS_AMOUNT - 1;
          this.removeErrorHandler(removeOnCompleteListenerReelIdx);
          this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            getNonNullableValue(this.nextResult.bet.result.reelPositions),
            this.stopSymbolSounds,
            this.anticipationInfos,
            this.announceType,
            this.spinResult,
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.skipWinAnimations();
      eventManager.emit(EventTypes.START_SPIN_ANIMATION, isTurboSpin); // change order skipAnimation
      //eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.announceType = 'None';
      this.anticipationInfos = [];
      this.stopSymbolSounds = [];
      setIsAllowForceReelStop(true);
      setAnticipationSymbolType(undefined);
      setIsSpinShortCut(false);
      this.setState(SlotMachineState.SPIN);

      let spinAnimation: AnimationGroup;
      if (isBuyFeatureMode()) {
        spinAnimation = this.getSynchroSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);
      } else if (isFreeSpinsMode(setGameMode())) {
        spinAnimation = this.getRespinSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);
      } else {
        spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);
      }
      if (isFreeSpinsMode(setGameMode())) {
        eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
          mode: setGameMode(),
          bgType: RespinActionInfo['respinBgType'][getRespinMultipleCount()]!,
        });
        setRespinCnt(setRespinCnt() + 1);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipWinAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id!);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getNonNullableValue(this.nextResult.bet.result.reelPositions),
              this.stopSymbolSounds,
              this.anticipationInfos,
              this.announceType,
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getRespinSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    const animationChain = new AnimationChain();
    const cutinAnimation = Tween.createDelayAnimation(0); //cutin animation
    animationChain.appendAnimation(cutinAnimation);
    cutinAnimation.addOnStart(() => eventManager.emit(EventTypes.START_CUTIN_ANIMATION));

    const reel = this.reelsContainer.reels[RESPIN_REEL_INDEX]!;
    const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

    spinAnimation.getFakeRolling().addOnChange(() => {
      if (this.nextResult && !this.isReadyForStop) {
        this.isReadyForStop = true;
        this.removeErrorHandler(RESPIN_REEL_INDEX);
        this.dynamicReelSetChangeRolling(this.nextResult.bet.reelSet!.id);
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          getNonNullableValue(this.nextResult.bet.result.reelPositions),
          this.stopSymbolSounds,
          [{ symbolCount: 0 }],
          'None',
          this.spinResult,
        );
      }
    });
    spinAnimation.getFakeRolling().addOnComplete(() => {
      console.log('fakeRollingComplete');
      this.throwTimeoutError();
    });
    this.reelsContainer.reels[RESPIN_REEL_INDEX]!.isPlaySoundOnStop = true;

    if (!this.nextResult) {
      spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
    }
    animationChain.appendAnimation(spinAnimation);
    animationGroup.addAnimation(animationChain);
    return animationGroup;
  }

  private getSynchroSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin, true);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getNonNullableValue(this.nextResult.bet.result.reelPositions),
              this.stopSymbolSounds,
              [{ symbolCount: 0 }],
              'None',
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): ResultOf<typeof userBonusFragment> | undefined | null {
    return getBonusFromRewards(this.nextResult, 'FREE_SPIN');
  }

  private getSpecialRound(): ResultOf<typeof userBonusFragment> | undefined | null {
    return getBonusFromRewards(this.nextResult, 'SPECIAL_ROUND');
  }

  private onCountUpEnd(): void {
    //const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    const specialRoundBonus = this.getSpecialRound();
    eventManager.emit(EventTypes.ADD_WIN_AMOUNT, this.nextResult!.bet.result.winCoinAmount);

    if (specialRoundBonus) {
      const isBonus = getBonusKind(specialRoundBonus.bonus!.id);
      if (isBonus === BonusKind.FREE_SPINS) {
        if (mode != GameMode.FREE_SPINS) {
          const winAmount = this.nextResult?.bet.result.winCoinAmount;
          setLastRegularWinAmount(winAmount);
          setCurrentFreeSpinsTotalWin(winAmount);
          setWinAmount(winAmount);
          setCurrentBonus({
            ...specialRoundBonus,
            isActive: setCurrentBonus().isActive,
          });
          setRespinSymbolType(getRespinSymbolType(this.nextResult?.bet.data.bonuses[0]?.bonusId!)!);

          const winDispDelay = Tween.createDelayAnimation(500);
          winDispDelay.start();
          winDispDelay.addOnComplete(() => {
            this.startFreeSpins();
            //this.setState(SlotMachineState.IDLE);
          });
        } else {
          setCurrentBonus({
            ...specialRoundBonus,
            isActive: setCurrentBonus().isActive,
          });
          setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
          this.setState(SlotMachineState.IDLE);
        }
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        this.setState(SlotMachineState.IDLE);
      } else if (isFreeSpinsMode(mode)) {
        //soldOut bonus
        if (isRespinLimitBonus(this.nextResult)) {
          setCurrentFreeSpinsTotalWin(
            setCurrentFreeSpinsTotalWin() +
              this.nextResult!.bet.result.winCoinAmount -
              getRespinLimitBonusAmount(this.nextResult),
          );
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.hideWinCount();

          const callback = () => {
            setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + getRespinLimitBonusAmount(this.nextResult));
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
            eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
            eventManager.emit(EventTypes.END_FREESPINS);
          };
          const winCountFadedelay = Tween.createDelayAnimation(BIG_WIN_END_DURATION + 50); // winCount fade wait
          winCountFadedelay.addOnComplete(() => {
            eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
            eventManager.emit(
              EventTypes.START_SOLDOUT_WINCOUNT_ANIMATION,
              getRespinLimitBonusAmount(this.nextResult),
              callback,
            );
            setIsRespinRevivalWaiting(true);
            eventManager.emit(EventTypes.CREATE_SOLDOUT_MESSAGE_BANNER, {
              preventDefaultDestroy: true,
              callback: () => {},
            });
          });
          winCountFadedelay.start();
        } else if (!isSuccessRespin(this.nextResult)) {
          //end freespin
          const freespinsEndingDelay = Tween.createDelayAnimation(RESPIN_ENDING_DELAY_DURATION);
          freespinsEndingDelay.addOnComplete(() => {
            setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
            setCurrentBonus({ ...setCurrentBonus(), isActive: false });
            eventManager.emit(EventTypes.END_FREESPINS);
          });
          freespinsEndingDelay.start();
        } else {
          throw new Error('end of freespin but success is true');
        }
      }
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
  }

  private dynamicReelSetChange(reelSetId: string): void {
    if (isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.RESPIN_CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      //reelType?
    } else {
      if (setReelSetId() !== reelSetId) {
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
          reelPositions: [0, 0, 0, 0, 0],
        });
      }
    }
    if (setReelSetId() !== reelSetId) {
      setReelSetId(reelSetId);
    }
  }

  private dynamicReelSetChangeRolling(reelSetId: string): void {
    if (isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.RESPIN_CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
    } else {
      if (setReelSetId() !== reelSetId) {
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
          reelPositions: [0, 0, 0, 0, 0],
        });
      }
    }
    if (setReelSetId() !== reelSetId) {
      setReelSetId(reelSetId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
    setIsBuyFeatureSpin(false);
    setIsBuyFeaturePurchased(false);
  }

  private getAnticipationInfo(spinResult: ISettledBet): AnticipationInfo[] {
    if (!ANTICIPATION_ENABLE || isFreeSpinsMode(setGameMode()) || setIsBuyFeatureSpin()) {
      return [{ symbolCount: 0 }];
    }
    const anticipationInfo = spinResult!.paylines
      .filter(
        (line) =>
          getNonNullableValue(line!.winPositions).length >= ANTICIPATION_SHORT_START_REELID &&
          isRespinSlotId(this.spinResult[line!.winPositions![0]!]!.id),
      )
      .map((line) => ({
        symbolCount: line!.winPositions!.length,
        symbol: this.spinResult[line!.winPositions![0]!]!.id,
      }));

    return anticipationInfo.length > 0 ? anticipationInfo : [{ symbolCount: 0 }];
  }

  private getStopSoundSymbolCount(anticipationInfos: AnticipationInfo[]): ReelStopSoundType[] {
    const reelSnd: ReelStopSoundType[] = ['normal', 'normal', 'normal', 'normal', 'normal'];

    const maxCountInfo = anticipationInfos.reduce((acc, info) => {
      return acc.symbolCount > info.symbolCount ? acc : info;
    });
    if (maxCountInfo.symbolCount >= 3) {
      reelSnd[2] = '3of';
    }
    if (maxCountInfo.symbolCount >= 4) {
      reelSnd[3] = '4of';
    }
    if (maxCountInfo.symbolCount >= REELS_AMOUNT) {
      reelSnd[4] = '5of';
    }
    return reelSnd;
  }

  private getAnnounceType(nextResult: ISettledBet): AnnounceType {
    if (isBuyFeatureMode() || isFreeSpinsMode(setGameMode())) return 'None';
    const anticipationInfo = this.getAnticipationInfo(nextResult);
    const { symbolCount, symbol } = anticipationInfo.reduce(
      (max, info) => (info.symbolCount > max.symbolCount ? info : max),
      { symbolCount: 0 },
    );

    const info = AnnounceDataTbl.find(
      (info) =>
        info.symbolCount === symbolCount &&
        info.symbols.includes(symbol!) &&
        info.guaranteedMin <= getRespinGuaranteedNum(this.nextResult),
    );
    return info && info.lotTbl.length > 0
      ? AnnounceConvTbl[getResultFromTbl(info.lotTbl, getRandomFromUUID(this.nextResult!.bet.id, 1000))]!
      : 'None';
  }

  private skipWinAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    let spinResult = [];
    let reelPositions = [];
    let reelSet: ReelSet = {
      id: result?.bet.reelSet?.id!,
      layout: this.slotConfig.reels.find((reelSet) => reelSet.id === result?.bet.reelSet?.id!)?.layout!,
    };

    if (isFreeSpinsMode(setGameMode())) {
      reelPositions = result!.bet.data.features.gameRoundStore.baseReelPositions.slice(0, REELS_AMOUNT);
      reelPositions[2] = getNonNullableValue(result!.bet.result.reelPositions)[0]!;
      reelSet = this.makeRespinReelSet(result?.bet.data.features.gameRoundStore.baseReelId!, result?.bet.reelSet?.id!);
    } else if (isBuyFeatureMode()) {
      reelPositions = result!.bet.data.features.gameRoundStore.baseReelPositions.slice(0, REELS_AMOUNT);
      result?.bet?.result && (result.bet.result.reelPositions = reelPositions);
    } else {
      reelPositions = getNonNullableValue(result!.bet.result.reelPositions).slice(0, REELS_AMOUNT);
    }

    spinResult = getSpinResult3x5({
      reelPositions,
      reelSet: reelSet,
      icons: this.slotConfig.icons,
    });

    this.spinResult = spinResult;
    setPrevReelsPosition(reelPositions);
    setPrevReelSet(reelSet);
    this.nextResult = result;
    setNextResult(result);
    setIsRespinRevivePattern(isRespinRevivePattern(this.spinResult));
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult!.balance.placed);
    }
    if (isBaseGameMode(setGameMode())) {
      this.anticipationInfos = this.getAnticipationInfo(this.nextResult);
      this.announceType = this.getAnnounceType(this.nextResult);
      this.stopSymbolSounds = this.getStopSoundSymbolCount(this.anticipationInfos);

      const allow =
        this.announceType != 'None' ||
        this.anticipationInfos.find((a) => a.symbolCount >= ANTICIPATION_LONG_START_REELID)
          ? false
          : true;
      setIsAllowForceReelStop(allow);
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    setIsSpinShortCut(false);
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.spinResult);

    if (isFreeSpinsMode(setGameMode())) {
      setIsRespinRevivalWaiting(true);
    }

    if (isFreeSpinsMode(setGameMode()) && setIsRespinRevivePattern()) {
      const reelStoppingDelay = Tween.createDelayAnimation(REVIVAL_REEL_STOP_DURATION);
      const totalDelay = Tween.createDelayAnimation(REVIVAL_REEL_STOP_DURATION + REVIVAL_ANIMATION_DURATION);
      reelStoppingDelay.addOnComplete(() => {
        eventManager.emit(EventTypes.START_REVIVE_ANIMATION);
        const cutinWaitDelay = Tween.createDelayAnimation(1000);
        cutinWaitDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.REPLACE_SLOT_SYMBOL, 2, 1, setRespinSymbolType());
        });
        cutinWaitDelay.start();
      });
      totalDelay.addOnComplete(() => {
        this.setState(SlotMachineState.JINGLE);
      });
      reelStoppingDelay.start();
      totalDelay.start();
    } else {
      this.setState(SlotMachineState.JINGLE);
    }
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false, !setIsAllowForceReelStop());
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(_width: number, _height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.paylines.length > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      setIsRespinRevivalWaiting(false);

      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode()) && setCurrentBonus().isActive) {
        this.skipWinAnimations();
        const freeSpinsRoundInterval = Tween.createDelayAnimation(setIsTurboSpin() ? 150 : 300);
        freeSpinsRoundInterval.addOnComplete(() => {
          eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
        });
        freeSpinsRoundInterval.start();
      }
      setBrokenGame(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (
        getBonusKind(this.getSpecialRound()?.bonus!.id!) === BonusKind.FREE_SPINS &&
        !isFreeSpinsMode(setGameMode())
      ) {
        const jingleDelay = Tween.createDelayAnimation(JINGLE_TO_WIN_DURATION);
        jingleDelay.addOnStart(() => {
          BgmControl.stopAll();
          eventManager.emit(EventTypes.START_FS_WIN_ANIMATION);
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        const reelStopAnimationDelay = Tween.createDelayAnimation(800);
        reelStopAnimationDelay.addOnComplete(() => {
          jingleDelay.start();
        });
        reelStopAnimationDelay.start();
      } else if (this.hasWin()) {
        const jingleDelay = Tween.createDelayAnimation(
          setIsTurboSpin() ? WIN_ANIM_START_DELAY / 2 : WIN_ANIM_START_DELAY,
        );
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        setIsRespinRevivalWaiting(false);
        const winDelayDuration = isFreeSpinsMode(setGameMode()) ? 500 : 0;
        const winDelay = Tween.createDelayAnimation(winDelayDuration);
        winDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
        });
        winDelay.start();
      } else {
        eventManager.emit(EventTypes.COUNT_UP_END);
      }
    }
  }
  private makeRespinReelSet(baseReelId: string, reelSetId: string): ReelSet {
    const reelSet = { ...this.slotConfig.reels.find((reelSet) => reelSet.id === reelSetId)! };

    if (reelSet.layout.length === REELS_AMOUNT) {
      return reelSet;
    }
    if (isFreeSpinsMode(setGameMode()) || setBrokenGame() || reelSet.layout.length === 1) {
      const baseGameReelSet: ReelSet = this.slotConfig.reels.find((reelSet) => reelSet.id === baseReelId)!;
      const layout = baseGameReelSet.layout.map((layout, index) => {
        if (index === 2) {
          return reelSet.layout[0]!;
        }
        return layout;
      });
      reelSet.layout = layout;
      reelSet.id = reelSetId;
    }
    return reelSet;
  }
}

export default SlotMachine;
